import "bootstrap/dist/css/bootstrap.min.css";
import { Button, ButtonGroup, Card, Col, Form, Modal, Row, Stack } from "react-bootstrap"; 
import './articulos.css';  
import { useEffect, useReducer, useState } from "react";
import Pagination from 'react-bootstrap/Pagination';
import logo from '../../img/logo2.png'; 
import emptyIcon from '../../img/empty.svg';
import checkIcon from '../../img/svg/comprobado.svg'

import api from '../../api/api' 
import { Loading } from "../loading/loading";
import axios from "axios";
import CurrencyFormat from "react-currency-format";
import DecimalConstructor from "decimal.js";
import moment from "moment"; 

export function Articulos() { 
 
    const initialState = {
        rows: [],
    };
      
      const reducer = (state, action) => {
        switch (action.type) {
          case 'AGREGAR_ARTICULO':
            return {
              ...state,
              rows: [...state.rows, action.payload],
            };
          case 'BORRAR_ARTICULO':
            return {
              ...state,
              rows: state.rows.filter((articulo) => articulo.id !== action.payload),
            };
            case 'BORRAR_ARTICULOS':
                return {
                  ...state,
                  rows: [],
                };
          case 'SUMAR_CANTIDAD':
            return {
              ...state,
              rows: state.rows.map((articulo) =>
                articulo.SubsidiaryAccount === action.payload.codigo
                  ? { ...articulo, Qty: articulo.Qty + 1, Amount: articulo.Price * (articulo.Qty + 1) }
                  : articulo
              ),
            };
          case 'RESTAR_CANTIDAD':
            return {
              ...state,
              rows: state.rows.map((articulo) =>
                articulo.SubsidiaryAccount === action.payload.codigo
                  ? { ...articulo, Qty: articulo.Qty - 1, Amount: articulo.Price * (articulo.Qty - 1) }
                  : articulo
              ).filter((articulo) => articulo.Qty > 0),
            };
          default:
            return state;
        }
      };
 
      const [state, dispatch] = useReducer(reducer, initialState);
      const { rows } = state;
    
      // Función para agregar un artículo
      const agregarArticulo = (nuevoRow) => { 
        if(itemSelected(nuevoRow.SubsidiaryAccount)) {    
            sumarCantidad(nuevoRow.SubsidiaryAccount)
        } else {
            nuevoRow.Amount = nuevoRow.Price;
            dispatch({ type: 'AGREGAR_ARTICULO', payload: nuevoRow });
        }
      };
    
      // Función para borrar un artículo
      const borrarArticulo = (idArticulo) => {
        dispatch({ type: 'BORRAR_ARTICULO', payload: idArticulo });
      };
    
      const borrarArticulos = () => {
        dispatch({ type: 'BORRAR_ARTICULO'});
      };

      // Función para sumar la cantidad de un artículo
      const sumarCantidad = (codigo) => {
        dispatch({ type: 'SUMAR_CANTIDAD', payload: { codigo: codigo } });
      };
    
      // Función para restar la cantidad de un artículo
      const restarCantidad = (codigo) => {
        dispatch({ type: 'RESTAR_CANTIDAD', payload: { codigo: codigo } });
      };

 
    //todas la clasificacion de productos  
    const subfamilias = [
        {
            nombre: 'INSTITUCIONAL',
            id: 24,
        },
        {
            nombre: 'IMPULSIVO',
            id: 25
        },
        {
            nombre: 'FAMILIAR',
            id: 26
        }
    ]
    const [grupos, setGrupos] = useState([]);

    //Informacion del cliente
    const [cliente, setCliente] = useState();

    const [header, setHeader] = useState();
    const [total, setTotal] = useState(0);
    const [showModalResult, setShowModalResult] = useState(false);
    const [entry, setEntry] = useState();
    const [validPedido, setValidPedido] = useState(false);



    //Clasficacion de productos seleccionadas  
    const [subFamiliaSelect, setSubFamiliaSelect] = useState("");
    const [grupoSelect, setGrupoSelect] = useState("");

    //Variable para buscar 
    const [search, setSearch ] = useState('');

    //Data
    const [data, setData ] = useState([]);

    //paginacion
    const [totalItems, setTotalItems] = useState(0); //total de elementos
    const [currentPage, setCurrentPage] = useState(1); //pagina


    const [loading, setLoading] = useState(false); //articulos
    const [loadingPedido, setLoadingPedido] = useState(false); //articulos

    //Este hook se ejecuta unicamente al incial el componente y guardo los grupos de inventario y la infomacion del cliente 
    useEffect(() => {   

        const fetchData = async () => {
            try {
            const gruposResponse = await api.get('/pedidos/grupos_inventario', {
                headers: {
                    "Authorization" : `Bearer ${localStorage.getItem('token')}`,
                }
            })  
            if(gruposResponse) {
                console.log(gruposResponse); 
            }
        
            const clienteResponse = await api.get('/pedidos/cliente', {
              headers: {
                "Authorization" : `Bearer ${localStorage.getItem('token')}`,
                accept: 'application/json',
                'Content-Type': 'application/json',
              },
            });
            setCliente(clienteResponse.data); 
            setGrupos(gruposResponse.data);
        } catch (error) {
            console.log(error);
            if(error) {
                if (error.response ) {
                switch(error.response.status) {
                    case 401: 
                        console.log('no tengo autorizacion'); 
                    break
                    default: 
                        console.log('Revisar algo paso '); 
                    break
                }
            }
            }
          }
        }; 
         
            fetchData();  
    }, []) 

    
    //Organizo el encabezado 
    const generateHeader = (data) => {
        setHeader({
            Entry: getEntryTemp(data),
            SubsidiaryAccount: data?.codigo,
            EntryDate: new Date(moment.utc().format()),
            Currency: 'USD',
            Description: null,
            tipoIVA: 'EXENTO',
            tarifasID: data?.cliente?.tarifasID,
            vendedorID: cliente.cliente.vendedorPreferido,

        }) 
    }
 
    const getEntryTemp = () => {
        return 'TEMP-' + 'FP' + Date.now() + cliente?.id;
    }

    useEffect(() => {
        if (cliente) {
          generateHeader(cliente);
          load(1)
        }
      }, [cliente]);

      useEffect(() => {
        if (cliente && rows.length > 0) {
            setValidPedido(true)
        } else {
            setValidPedido(false)
        }
      }, [cliente, rows]);
 
    //Ejecuta la peticion de buscar  cada vez que se le hace una busqueda o se lecciona un categoria 
    useEffect(() => {
        if(cliente && localStorage.getItem('token')) {
            load(1);
        }
        // eslint-disable-line react-hooks/exhaustive-deps
    }, [search, subFamiliaSelect, grupoSelect]);

    useEffect(() => {
        setTotal(sumOf(rows, 'Amount'));
    }, [rows])
 
    //metodo de buscar 
    const load = (index) => {
        let query = {
            search: search, 
            subFamiliasID: subFamiliaSelect === "" ? null : subFamiliaSelect,
            gruposInventarioID: grupoSelect === "" ? null : grupoSelect,
            page: index,
            offset: (index - 1) * 12,
        } 
 
        setCurrentPage(index); 
        setLoading(true);
        
        api.get('/pedidos/articulos', {
            headers: {
                "Authorization" : `Bearer ${localStorage.getItem('token')}`,
            },
            params: query, 
        }).then(response => {
            console.log(response.data)
            setData(prepararData(response.data.data));
            setTotalItems(response.data.total)
            setLoading(false)

        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log('Solicitud cancelada:', error.message);
              } else {
                console.error('Error en la solicitud:', error);
              }
        })
    }
 
    const prepararData = (items) => {

        items.forEach(element => { 
            let empaqueIndex = element.articulo.empaques.findIndex(e => e.unidad.unidad = 'CES'); 
            if(empaqueIndex > -1) {
                element.Price = element.articulo.empaques[empaqueIndex].precios.find(e => e.tarifasID === cliente.cliente.tarifasID)?.monto ?? 0;
                element.Unit = element.articulo.empaques[empaqueIndex].unidadesID;
                element.unidad = element.articulo.empaques[empaqueIndex].unidad; 
            } else {
                element.Price = 0;
            }
        });

        return items
    }
 
    // Muestra el numero de paginas para la paginacion de la tabla 
    let items = [];
        for (let index = 1; index <= Math.ceil(totalItems / 12); index++) {
            items.push(
                <Pagination.Item
                onClick={() => load(index)}
                key={index}
                active={index === currentPage}
                >
                {index}
            </Pagination.Item>
        );
    } 

    //Devuelve true si el codigo que es enviado ya existe en el array Rows es decir ya fue seleccionado para el pedido
    const itemSelected = (codigo) => {
        const findIndex = rows.findIndex((item) => item.SubsidiaryAccount === codigo);
        return findIndex >= 0 ? rows[findIndex] : null;
    }

    const prepareRow = (data) => {
        return {
            RegID: null, 
            SubsidiaryAccount: data.codigo,
            Description: data.nombre, 
            Qty: 1,
            Price: new DecimalConstructor(data.Price).toFixed(2),
            Unit: data.Unit,
            unidad: data.unidad,
            Amount: 0,
            Entry: header.Entry
        }
    } 

    const sumOf = (array, key) => {
        return array.reduce((p, c) => {
          return +p + Math.abs(+c[key]);
        }, 0);
    }
 
    const onclose = () => {
        setShowModalResult(false);
        setEntry();
        generateHeader(cliente);
    }

    const submit = () => {

        if(validPedido) {
            let data = {
                ...header,
                rows: rows  
            }
    
            setLoadingPedido(true)
            api.post('/pedidos/pedido', data, {
                headers: {
                    "Authorization" : `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(response => {
                setLoadingPedido(false);
                setHeader(null);
                borrarArticulos();
                setEntry(response.data.Entry);
                setShowModalResult(true);
    
            }).catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Solicitud cancelada:', error.message);
                  } else {
                    console.error('Error en la solicitud:', error);
                  }
            })
        } 
 
    }


    const opcionesGrupo = () => {

        let gruposFilter = []

        console.log(subFamiliaSelect);
        if(subFamiliaSelect !== "" && subFamiliaSelect !== null) { 
            console.log('entrp');
            console.log(grupos.filter(e => e.subFamiliasID === parseInt(subFamiliaSelect)))
            gruposFilter = grupos.filter(e => parseInt(e.subfamiliasID) === parseInt(subFamiliaSelect)); 
        } else {
            gruposFilter = grupos;
        }
 
        console.log(gruposFilter)
        return  <>
            {
                gruposFilter.map((e, index) => (
                    <option value={e.id} key={index}>{e.nombre}</option>
                ))
            }
        </>
    }
     
     
    return (
        <div className="content">
            <Row className="align-items-stretch g-2"> 
                <Col md="7" lg="8"> 
                    <Card>
                        <Card.Header>
                            <Row >
                                <Col xs={3} >
                                    Productos ({totalItems})
                                </Col>
                                <Col xs={9} className="d-flex justify-content-end text-end">

                                <Stack direction="horizontal" gap={3}> 
                                <Form.Control size="sm"  placeholder="Buscar..." value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <div className="vr" />
                                    <p className="mb-0">
                                        Categoria:
                                        </p>
                                        <Form.Select size="sm" className="ms-2" onChange={(e) =>  setSubFamiliaSelect(e.target.value)}>
                                        <option value={""} >Todas</option>
                                        {
                                            subfamilias.map((e, index) => ( 
                                                <option value={e.id} key={index}>{e.nombre}</option>
                                            ))
                                        }
                                        </Form.Select> 
                                    <div className="vr" />
                                    <p className="ms-2 mb-0 me-auto w-100" style={{whiteSpace: "nowrap"}}>
                                            Sub-categoria:  
                                        </p> 
                                        <Form.Select size="sm"    onChange={(e) => setGrupoSelect(e.target.value)}>
                                        <option value={""}>Todas</option>
                                        {opcionesGrupo()}
                                        </Form.Select>
                                    </Stack> 
                                </Col>
                            </Row>
                            
                            </Card.Header>
                            <Card.Body style={{position: 'relative'}} className="px-1">
                            <Loading  loading={loading} text="Cargando articulos..."/> 
                                <div  className="articulosContent"> 

                                    {
                                        data.length === 0 ? 
                                        <div className="d-flex justify-content-center h-100 align-items-center">
                                            <div className="text-center">
                                               <img  src={emptyIcon} alt="vacio" className="w-100"  style={{height: '200px'}}/>
                                               <p className="mt-3 text-primary ">No se ha encontrado nada</p>
                                            </div>
                                            
                                        </div>
                                        : null
                                    }

                                <Row className="g-2 ">  
                                    {
                                        data.map((e, index) => (
                                             <Col sm="6"   lg="3"  key={index} >
                                                {/* cursor-pointer  */}
                                                    <Card  className={`h-100  ${itemSelected(e.codigo) ? 'border-select' : ''}`} >
                                                        {
                                                            e.Price === 0 ? 
                                                            <div className="noPrice">
                                                                <div className="">
                                                                       <h5>No tiene precio</h5> 
                                                                </div>
                                                            </div>
                                                            : null
                                                        }
                                                        <Card.Img  className="px-3 pt-3 imgCard" variant="top" src={e.image ? 'https://v2.alliance-cloud.com/api/image?path='+ e?.image?.toString() : logo} />
                                                         <Card.Body className="pt-2">
                                                              <hr className="m-0 mb-2" />
                                                              <div className="d-flex flex-column h-100">
                                                                    <Card.Text className="fs-7   mb-0">{e.nombre}</Card.Text>
                                                                    {/* <Card.Title className="mb-0">${e.precio}</Card.Title> */}
                                                            </div>
                                                         </Card.Body>
                                                         <div className="d-grid gap-2">
                                                            <Button type="button"  variant="primary" size="sm" className="customButtom"  disabled={e.Price === 0} onClick={() => agregarArticulo(prepareRow(e))}>
                                                                <div className="d-flex justify-content-between">
                                                                    <span>Agregar</span>
                                                                    <span>
                                                                        <CurrencyFormat value={e.Price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />
                                                                    </span> 
                                                                </div>
                                                            </Button>
                                                        </div>
                                                    </Card>
                                             </Col> 
                                        ))
                                    }
                                </Row>
                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <div className="d-flex justify-content-end">
                                <Pagination className="mb-0"> 
                                    {items} 
                                    </Pagination>
                                </div>
                            </Card.Footer>
                    </Card>
                </Col>
                <Col md="5" lg="4"  >
                <Card className="h-100 border-0" >
                <Loading  loading={loadingPedido} text="Enviando pedido..."/> 
                        <Card.Header> 
                            <Row>
                                <Col>
                                    <div>Pedidos</div> 
                                </Col>
                                <Col className="text-end">
                                <h6 className="mb-0">{header?.Entry ?? ''}</h6>
                                </Col>
                            </Row>
                            <div style={{fontSize: '13px'}}>Cliente: {cliente?.nombre} - <b>{cliente?.codigo}</b></div>
                        </Card.Header>
                        <div className="contentPedido">
                        <Card.Body className="scrollPedidos p-2">  
                        {
                            rows.map((e, index) => (
                                <Card className="mb-1 fs-7" key={index} > 
                                        <Card.Body className="p-0"> 
                                            <Row className="g-0">
                                                    {/* <Col sm="2" className="p-1">
                                                        <img  className="imgCardPedido w-100" variant="top" src={e.image ? 'https://v2.alliance-cloud.com/api/image?path='+ e?.image?.toString() : logo} alt="p"/>
                                                    </Col> */}
                                                    <Col sm="12" className="d-flex justify-content-between p-1">
                                                        <div>
                                                            <div>
                                                                {e.Description}
                                                            </div>
                                                            <div>
                                                            <b>{e.Qty} {e.unidad.nombre} X   <CurrencyFormat value={e.Price} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} /></b>  
                                                            </div> 
                                                        </div>
                                                        <div className="d-flex align-items-center">  
                                                            <CurrencyFormat value={e.Amount} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} renderText={value => <div className="me-2 mb-0"><h5 className="mb-0">{value}</h5></div>} />
                                                        <ButtonGroup size="md" >
                                                            <Button variant="danger" className="pt-0" onClick={() =>restarCantidad(e.SubsidiaryAccount)}>-</Button>
                                                            {/* <Button variant="outline-light" className="p-1 pt-0"><img src={EditIcon}  width={25} alt="l"/></Button> */}
                                                            <Button className="buttomRight pt-0"  onClick={() =>sumarCantidad(e.SubsidiaryAccount)}>+</Button>
                                                        </ButtonGroup>
                                                            </div> 
                                                    </Col>
                                            </Row>      
                                        </Card.Body>   
                                </Card>
                            ))
                        } 
                        </Card.Body>
                      
                        <Row className="px-3 py-2 shadow-sm" >
                            {/* <Col sm="6">
                                Sub-total
                            </Col>
                            <Col sm="6" className="text-end">
                              <b>  $0.00</b>
                            </Col>
                            <Col sm="6">
                               Iva 16%
                            </Col>
                            <Col sm="6" className="text-end">
                            <b>  $0.00</b>
                            </Col> */}
                            <Col sm="6">
                               Total  
                            </Col>
                            <Col sm="6" className="text-end">
                            <CurrencyFormat value={total} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={'$'} renderText={value => <b>{value}</b>} /> 
                           
                            </Col>
                            <Col sm="12">
                                Este pedido no incluye I.V.A.
                            </Col>
                        </Row>
                        <div className="d-grid gap-2">
                              <Button type="button" className="customButtom"  variant="primary" size="lg" disabled={!validPedido} onClick={submit}>Enviar</Button>
                         </div> 
                        </div>
                    </Card>
                </Col>
            </Row> 

            <Modal show={showModalResult} centered> 
                <Modal.Body className="d-flex justify-content-center">
                        <div className="text-center">   
                            <h4 className="fw-light">Pedido creado correctamente</h4>
                            <h5 className="fw-light">Numero de pedido:</h5>
                            <h3 className="mb-4">NO. {entry}</h3>
                            <p>Con este número, podra tene un seguimiento de su pedido en cualquier momento</p>
                            <img src={checkIcon} width={80} className="mt-3" alt="icon"/> <br/>
                            <Button variant="success"  className="mt-3" onClick={() => onclose()}>Cerrar</Button>
                        </div>
                </Modal.Body> 
            </Modal>
        </div>
    )

}
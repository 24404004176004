 
import './App.css';
import logo from './img/logo2.png';
import logout from './img/svg/logout.svg';
import { Login } from './app/login/login'; 
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Articulos } from './app/articulos/articulos';
import { Footer } from './app/footer/footer';
import ProtectedRoute from './app/const ProtectedRoute'; 
import { useLocalStorage } from 'react-use';
import { useState } from "react";
import { Col, Row } from 'react-bootstrap';


function App() {

  const [user, setUser] = useState(localStorage.getItem('token'));
  const navigate = useNavigate(); 

  const  onLogout = () => {
    localStorage.removeItem('token');
    setUser(null)
    navigate("/");  
  }

   
  return (
    <div className='contentApp'>
      <div className="App">
        <Row className="App-header"> 
          <Col sm={{ span: 4, offset: 4 }} className='text-center'>
            <img src={logo} alt='logo' className='logo'/>
          </Col>
          <Col sm={4} className='text-end p-3'>
            {
              localStorage.getItem('token') ? 
              <div className='text-black d-flex justify-content-end align-items-center pe-3'> 
                <h6 className='mb-0 cursor-pointer' onClick={onLogout} >Cerrar sesión</h6>  
                <img src={logout} alt='logout'  width={40} className='cursor-pointer ps-2' onClick={onLogout} />
              </div>
              : null
          }
          </Col>
          </Row>   
        <Routes>
         
          <Route element={<ProtectedRoute canActivate={localStorage.getItem('token')} redirectPath='/' />}>
              <Route path="articulos" element={ <Articulos /> } onEnter={() => console.log('Entered /')}  />  
          </Route>  
          <Route element={<ProtectedRoute canActivate={!localStorage.getItem('token')} redirectPath='/articulos' />}>
            <Route path="/" element={ <Login /> } />
          </Route>  
        </Routes> 
      </div>
      {/* <Footer/> */}
    </div>
  );
}

export default App;

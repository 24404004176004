import { Button, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap"; 
import './login.css'; 
import EyeIcon from '../../img/svg/eye.svg'
import CloseEyeIcon from '../../img/svg/close-eye.svg'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import api from '../../api/api'  
import { Loading } from "../loading/loading";
import infoIcon from '../../img/svg/información.svg';

 
 

export function Login() {

    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate(); 
 
    const [form, setForm] = useState({
        codigo: '',
        password: ''
    }); 
 
    const [loading, setLoading] = useState(false); //pagina

    const [errores, setErrores] = useState({}); // Para almacenar mensajes de error
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // Botón deshabilitado inicialmente
    
    const [respError, setRespError] = useState({});
    const [showModalError, setShowModalError] = useState(false);
   
    const submit = (event) => { 
        event.preventDefault();
        validarFormulario();
        if (isSubmitDisabled) {
            setLoading(true)
            api.post('/login', form).then(response => {  
                localStorage.removeItem('token',  response.data.payload.token);
                localStorage.setItem('token', response.data.payload.token);     
                setLoading(false);
                navigate("/articulos");   
            })
            .catch(error => { 
                setLoading(false)
                if(error) {
                    console.log(error);
                    console.log(error.response)
                    switch(error.response.data.httpCode) {
                        case 401 :
                            switch(error.response.data.code) {
                                case 'USERNOTFOUND':
                                    setRespError({
                                        titulo: 'Usuario no encontrado',
                                        parrafo: 'El usuario que ingreso no fue encontrado, verifique la información e intentelo nuevamente'
                                    }) 
                                break
                                default:
                                    setRespError({
                                        titulo: 'Usuario o contraseña incorrecta',
                                        parrafo: 'El usuario y la contraseña no coinciden'
                                    }) 
                                break
                            }
                        break
                        default:
                            setRespError({
                                titulo: 'Ha ocurrido un error',
                                parrafo: 'Contacte con el proveedor de servicio'
                            }) 
                        break
                    }
                    setShowModalError(true)
                }
            }); 
        }
    }

      // Función para validar el formulario
    const validarFormulario = () => {
        const newErrores = {};
        if (form.codigo.length < 3) {
            newErrores.campo1 = 'El campo codigo debe tener al menos 3 dígitos';
        }
        if (form.password.length < 3) {
            newErrores.campo2 = 'El campo contraseñaß debe tener al menos 3 dígitos';
        }
  
        setIsSubmitDisabled(Object.keys(newErrores).length === 0); // Habilita el botón si no hay errores
    };

    const onChangeForm = (event) => { 
        setForm({...form, [event.name]: event.value})
    }
 
    return(
 
    <Container  >
      <Row  className="justify-content-center aling-item-center mt-5">
        <Col xs="11" md="8" lg="6" xl="4" className="login" style={{position: 'relative'}} >
        <Loading  loading={loading} text="Cargando..."/> 
            <h3 className="text-center">Iniciar Sesión</h3>
            <div> 
            <Form  onSubmit={(event) => submit(event)}  onChange={validarFormulario}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Codigo</Form.Label>
                    <Form.Control type="text" name="codigo" placeholder="PR000000000" value={form.codigo} onChange={(event) => onChangeForm(event.target)} />
                    {errores.campo1 && <Form.Text id="passwordHelpBlock" muted>{errores.campo1}</Form.Text>} 
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Contraseña</Form.Label>
                    <InputGroup className="mb-3"> 
                        <Form.Control type={showPassword ? 'text' : 'password'} name="password"    value={form.password} onChange={(event) => onChangeForm(event.target)} />
                        <InputGroup.Text><img    className="cursor-pointer"   width={23} src={showPassword ? EyeIcon : CloseEyeIcon} onClick={() => setShowPassword(!showPassword)} alt="icon"/></InputGroup.Text>
                    </InputGroup> 
                    {errores.campo2 && <Form.Text id="passwordHelpBlock" muted>{errores.campo2}</Form.Text>}
                </Form.Group>
                <div className="d-grid gap-2">
                    <Button type="button"  variant="primary" size="md" onClick={submit} disabled={!isSubmitDisabled}>Iniciar sesión</Button>
                </div>
            </Form>
            </div>
        </Col>
      </Row>

            <Modal show={showModalError} centered> 
                <Modal.Body className="d-flex justify-content-center">
                        <div className="text-center">   
                            <h4>{respError.titulo}</h4>
                            <h6 className="fw-light">{respError.parrafo}</h6> 
                            <img src={infoIcon} width={65} className="mt-3" alt="icon"/> <br/>
                            <Button variant="primary" className="mt-3" onClick={() => setShowModalError(false)}>Cerrar</Button>
                        </div>
                </Modal.Body> 
            </Modal>
    </Container> 
    )

}

 